.footer {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  padding-top: 1px;
  border-top: 1px solid black;
  margin-top: 20px;
}

@media print {
  .dataBlockContainer {
    display: block !important;
  }

  .dataBlock {
    break-inside: avoid;
  }
}

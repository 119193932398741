.container {
  :global(tbody.MuiTableBody-root) > :nth-of-type(1n) {
    background-color: #004bb733;
    border-bottom: none;
  }
  :global(tbody.MuiTableBody-root) > :nth-of-type(2n) {
    background-color: white;
    border-bottom: none;
  }
}

.folderTypeTabs {
  border-bottom: 1px solid #e0e0e0;
  // background-color: #ffff;
}

.allTabs {
  background-color: #ffffff !important;
  white-space: nowrap !important;
  overflow: unset !important;
  box-shadow: 0px 1px 2px #00000026 !important;

  border-radius: 5px 5px 0px 0px !important;
  min-width: fit-content !important;
  padding: 1rem !important;
}

.activeTab {
  font-weight: 750 !important;
  margin-right: 2px !important;
  color: black !important;
  opacity: 0.87 !important;
}

.inactiveTab {
  margin-right: 2px !important;
  opacity: 0.9 !important;
  color: black !important;
}

.button {
  position: relative !important;
  padding: 0px !important;
  margin: 0px !important;
  margin-right: -3px !important;
}

.textField {
  .button {
    display: none;
  }
}

.textField:hover {
  .button {
    display: unset;
  }
}

.nowrap {
  white-space: nowrap;
}

.box {
  color: inherit;
  /* width: 300px; */
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  max-width: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.noPaddingLeft {
  padding-left: 0 !important;
  white-space: nowrap;
}

.noPaddingRight {
  padding-right: 0 !important;
  white-space: nowrap;
}

.shrinkToContent {
  width: 0.1%;
  white-space: nowrap;
}

.paddingHalf {
  padding-left: 0.875rem !important;
  padding-right: 0.875rem !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.8rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page-section {
  margin-bottom: 34px;
}

.paper-title {
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 1rem !important;
}

.label-section {
  font-size: 18px !important;
  font-weight: bold !important;
}

.expand-section {
  color: #006ad4;
  text-decoration: underline;
}

.label-title {
  break-after: avoid;
  font-weight: bold !important;
  text-transform: uppercase;
}

.label-title-nocase {
  break-after: avoid;
  font-weight: bold !important;
}

.label-title-caption {
  font-size: 0.65rem !important;
}

.label-title-desc {
  font-size: 14px !important;
  text-transform: lowercase;
  color: gray;
}

@media screen and (max-width: 414px) {
  .btn-responsive {
    width: 100% !important;
  }
}

#main-paper {
  color: black;
}

h6 {
  font-weight: bold !important;
}

hr {
  width: 80%;
  margin: 0 auto !important;
}

.paper-title {
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 1rem !important;
}

.label-title {
  font-weight: bold !important;
  text-transform: uppercase;
}

.label-title-nocase {
  font-weight: bold !important;
}

.label-title-caption {
  font-size: 0.65rem !important;
}

.MuiSelect-outlined {
  line-height: normal !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: 0.8rem !important;
}

.MuiOutlinedInput-input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 0.8rem !important;
}

.MuiOutlinedInput-input[readOnly] {
  background-color: #f3f3f3;
}

.MuiInputLabel-root {
  font-size: 0.8rem !important;
}

.MuiInputBase-input {
  font: inherit;
  font-size: 0.8rem !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.MuiAutocomplete-inputRoot {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  // padding-right: 0px !important;
}

tbody.MuiTableBody-root > :nth-of-type(1n) {
  background-color: #c8ead3;
  border-bottom: none;
}

tbody.MuiTableBody-root > :nth-of-type(2n) {
  background-color: #ffffff;
  border-bottom: none;
}

tbody.MuiTableBody-root > tr {
  height: 28px;
}

// tbody.MuiTableBody-root> :nth-of-type(1n) td {
//   border-bottom: none;
// }

thead.MuiTableHead-root > :nth-of-type(1) {
  height: 45px;
  white-space: nowrap;
}

thead.MuiTableHead-root > :nth-of-type(2) > th {
  background-color: #ececec;
}

thead.MuiTableHead-root > :nth-of-type(1) .MuiTableCell-root.MuiTableCell-head {
  // text-align: center !important;
  // padding: 8px 0 !important;
  // border-left: 1.2px solid #ccc;
  box-shadow: 0px 1px 3px 0px #ccc;
  // font: normal normal bold 14px/16px 'Segoe UI';
}

.MuiTableRow-root > tr:nth-child(1) > .MuiTableCell-root .MuiTableCell-body {
  background-color: #fff !important;
}

.MuiTableRow-root a {
  // font: normal normal 600 14px/19px 'Segoe UI';
  color: #006ad4;
  opacity: 0.8;
  text-decoration: none;
}

.MuiTableRow-root a:hover {
  text-decoration: underline;
}

.MuiTableRow-root > th {
  // font-size: 13px !important;
  // text-align: left;
  padding: 3px 28px 3px 28px;
}

.MuiTableRow-root > td {
  // font-size: 13px !important;
  // text-align: left;
  padding: 3px 28px;
}

// .MuiTableRow-root > td:nth-child(2),
// .MuiTableRow-root > td:nth-child(3),
// .MuiTableRow-root > td:nth-child(7),
// .MuiTableRow-root > td:nth-child(99) {
//   text-align: left !important;
// }

// .MuiTableRow-root:nth-child(2) td {
//   padding-top: 10px !important;
// }

.MuiTablePagination-root {
  margin: auto !important;
  text-align: center !important;
}

.MuiInputBase-input .MuiInput-input {
  border-bottom: none !important;
}

.report-list-filter-input .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  height: 34px !important;
}

// .datagrid_reviewer_holder {
//   display: table-cell;
//   vertical-align: middle;
// }

.datagrid_reviewer {
  display: block;
  // margin-top: 5px;
}

.menuitem_group {
  padding-left: 0 !important;
}

.menuitem_group .MuiListItemText-root span {
  font-weight: bold !important;
  opacity: 75%;
}

.currently_reviewing_column {
  display: flex;
  align-items: center;
}

@media print {
  button {
    display: none !important;
  }
}

.shadow-holder::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  width: 100%;
  height: 35px;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.8) 60%,
    rgba(255, 255, 255, 0.2) 100%
  );
}

.queryBuilder-invalid > .rule-fields {
  border-color: red;
}

.documentPrintingHeader {
  display: flex;
  border-bottom: 1px solid black;
  height: 5em;
  width: 100%;
  justify-content: space-between;
}

.logo {
  height: 100%;
}

.logoBox {
  width: 70px;
}

.centerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 8px 0;
}
